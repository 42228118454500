import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SurveyModel } from 'survey-core';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  GetDataUsingPromiseFn,
  TabulatorFilter,
  TabulatorSortOrder,
  Tabulator,
} from 'survey-analytics/survey.analytics.tabulator';

import { Typography } from 'src/shared/ui/typography';
import { useGetSurveyAnswersByFormIdQuery, useGetSurveyContentQuery } from 'src/store/api';
import { not, showToastErrorMessage, uncapitalizeString } from 'src/shared/utils';
import { BackButton } from 'src/shared/ui/backButton';
import {
  useDeleteSurveyAnswerMutation,
  useLazyGetSurveyAnswersByFormIdQuery,
  useLazyGetSurveyAnswersForExportByFormIdQuery,
} from 'src/store/api/surveyAnswer';
import { Spinner } from 'src/shared/ui/spinner';
import {
  SurveyAnswerEntity,
  PageSize,
  SortBy,
  SortOrder,
  SurveyElement,
  SurveyContentJSON,
} from 'src/shared/types';
import { Pagination } from 'src/shared/ui/pagination';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { PAGE_SIZE_OPTIONS } from 'src/shared/constants/pagination';
import { Button } from 'src/shared/ui/button';
import { useToggle } from 'src/shared/hooks/useToggle';

import { DeleteSurveyAnswerModal } from './Features/DeleteSurveyAnswerModal';
import {
  SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID,
  VisualizationPanel,
} from './Features/VisualizationPanel';
import { SearchField } from './Features/SearchField';
import { getAdaptedTabulatorAnswers } from './adapters';
import './style.css';
import { DisplayedQuestionsModal } from './Features/DisplayedQuestionsModal';
import {
  ExtraSurveyAnswerField,
  SEARCH_FIELD_DEBOUNCE_DELAY,
  DEFAULT_DISPLAYED_QUESTIONS_COUNT,
} from './constants';

const SurveyAnswers = () => {
  const { formId } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [formQuestions, setFormQuestions] = useState([] as SurveyElement[]);
  const [displayedQuestions, setDisplayedQuestions] = useState([] as string[]);
  const [isQuestionsModalOpen, toggleQuestionsModal, setQuestionsModalOpen] = useToggle(false);

  const getPageSize = (size: string | null): PageSize => {
    const parsed = parseInt(size || '5', 10);

    if (PAGE_SIZE_OPTIONS.includes(parsed as PageSize)) {
      return parsed as PageSize;
    }

    return 5 as PageSize;
  };

  const [pageSize, setPageSize] = useState<PageSize>(getPageSize(searchParams.get('pageSize')));
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [searchField, setSearchField] = useState<string>(searchParams.get('search') || '');
  const [survey, setSurvey] = useState<SurveyModel | null>(null);
  const sort = useRef<SortBy>({
    fieldKey: 'createdAt',
    order: SortOrder.DESC,
  });

  const debouncedSearchField = useDebounce(searchField, SEARCH_FIELD_DEBOUNCE_DELAY);

  const { data: form, isFetching: isLoadingForm } = useGetSurveyContentQuery(
    {
      id: formId || '',
      normalizedForAnswersPage: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const pathData = displayedQuestions.filter(
    (question) => !(Object.values(ExtraSurveyAnswerField) as string[]).includes(question),
  );

  const {
    data = {
      data: [],
      total: 0,
      totalPages: 0,
    },
    isFetching: isFetchingAnswers,
  } = useGetSurveyAnswersByFormIdQuery(
    {
      formId: formId ?? '',

      pagination: {
        pageSize: searchParams.get('pageSize') || '5',
        page: searchParams.get('page') || '1',
      },
      filters: {
        searchField: debouncedSearchField.trim(),
        path: pathData,
      },
      sort: {
        fieldKey: sort.current.fieldKey,
        order: sort.current.order,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !formId,
    },
  );

  const [fetchSurveyAnswersLazy, { isFetching: isFetchingAnswersLazy }] =
    useLazyGetSurveyAnswersByFormIdQuery();

  const [fetchSurveyAnswersForExportLazy] = useLazyGetSurveyAnswersForExportByFormIdQuery();

  const { data: answers, total } = data;

  const [deleteAnswer, { isLoading: isDeletingAnswer }] = useDeleteSurveyAnswerMutation();

  const [isDeleteSurveyAnswerModalOpen, setIsDeleteSurveyAnswerModalOpen] = useState(false);
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState<SurveyAnswerEntity | undefined>(
    undefined,
  );

  const openQuestionsModal = () => {
    setQuestionsModalOpen(true);
  };

  const closeQuestionsModal = () => {
    setQuestionsModalOpen(false);
  };

  useEffect(() => {
    if (!form) {
      return;
    }

    const paginatedQuestions = ((form.content as SurveyContentJSON).pages
      ?.map((page) => page.elements)
      .flat()
      .map(({ name, title }) => ({
        name,
        title,
      })) || []) as SurveyElement[];

    const extraFields = Object.values(ExtraSurveyAnswerField).map((name) => ({ name }));

    const questions = [...paginatedQuestions, ...extraFields];

    const defaultDisplayedQuestions = [
      ...questions.slice(0, DEFAULT_DISPLAYED_QUESTIONS_COUNT),
      ...extraFields,
    ].map(({ name }) => name);

    setFormQuestions(questions);
    setDisplayedQuestions(defaultDisplayedQuestions);
  }, [form]);

  const getTabulatorSurveyData = useCallback(
    async ({
      filter,
      sort: newSort,
    }: {
      filter?: Array<TabulatorFilter>;
      sort?: Array<TabulatorSortOrder>;
    }): ReturnType<GetDataUsingPromiseFn> => {
      let sortFieldName = sort.current.fieldKey;
      let sortDir = sort.current.order;
      let searchField = debouncedSearchField.trim();
      let searchPath = [...pathData];
      const timeStampFields = ['CreatedAt', 'UpdatedAt'];

      try {
        if (newSort && newSort?.length > 0) {
          const sortItem = newSort[0] as any;
          const columnName = sortItem.field.replace(' ', '');
          sortFieldName = timeStampFields.includes(columnName)
            ? uncapitalizeString(columnName)
            : `answer.${columnName}`;

          sortDir = sortItem.dir;

          if (sortFieldName !== sort.current.fieldKey || sortDir !== sort.current.order) {
            sort.current = {
              fieldKey: sortFieldName,
              order: sortDir,
            };
          }
        }

        if (filter && filter?.length > 0 && filter[0].value.trim()) {
          const pathField = filter[0].field.replace(' ', '');
          searchField = filter[0].value.trim();
          searchPath = timeStampFields.includes(pathField)
            ? [uncapitalizeString(pathField)]
            : [pathField];
        }

        const { data: allAnswers, total } = await fetchSurveyAnswersLazy({
          formId: formId ?? '',
          pagination: {
            pageSize: searchParams.get('pageSize') || '5',
            page: searchParams.get('page') || '1',
          },
          filters: {
            searchField,
            path: searchPath,
          },
          sort: {
            fieldKey: sortFieldName,
            order: sortDir,
          },
        }).unwrap();

        return {
          data: getAdaptedTabulatorAnswers(allAnswers || []),
          totalCount: total || 0,
          error: undefined,
        };
      } catch (error: any) {
        console.error(error);
        showToastErrorMessage(error.message || 'Failed to fetch survey answers');
        return {
          data: [],
          totalCount: 0,
          error: error.message,
        };
      }
    },
    [
      searchParams,
      pathData,
      debouncedSearchField,
      formId,
      sort,
      getAdaptedTabulatorAnswers,
      fetchSurveyAnswersLazy,
    ],
  );

  const handleNavigationClick = (href: string) => {
    navigate(href);
  };

  const handleSearchFieldChange = (searchFieldValue: string) => {
    setSearchField(searchFieldValue);
    setPage(1);

    if (!searchFieldValue) {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      newSearchParams.delete('search');

      setSearchParams(newSearchParams);

      return;
    }
    if (searchFieldValue) {
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        search: searchFieldValue,
      });
    }
  };

  const handlePageChange = (pageValue: number) => {
    setPage(pageValue);
    setSearchParams({
      page: pageValue.toString(),
      pageSize: pageSize.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handlePageSizeChange = (pageSizeValue: PageSize) => {
    setPageSize(pageSizeValue);
    setPage(1);
    setSearchParams({
      page: String(1),
      pageSize: pageSizeValue.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handleDownloadClick = async () => {
    if (!survey) {
      showToastErrorMessage('Survey in not initialized');

      return;
    }

    try {
      const { data: allAnswers } = await fetchSurveyAnswersForExportLazy({
        formId: formId || '',
      }).unwrap();

      const downloader = new Tabulator(survey, getAdaptedTabulatorAnswers(allAnswers), {
        downloadOptions: {
          fileName: 'results',
        },
      });
      downloader.render(SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID);
      downloader.download('csv');
      downloader.destroy();
    } catch {
      showToastErrorMessage('Failed to download the survey answers');
    }
  };

  if (not(form) && not(isLoadingForm)) {
    showToastErrorMessage('There was an error trying to load the form');

    navigate('/forms');

    return null;
  }

  return (
    <>
      {isLoadingForm && (
        <Spinner
          withBackdrop
          fallbackText="Loading form answers..."
        />
      )}

      {isDeletingAnswer && (
        <Spinner
          withBackdrop
          fallbackText="Deleting form..."
        />
      )}

      <div className="flex flex-col w-full gap-y-4 py-4 px-4">
        <div className="flex items-center gap-4 flex-wrap">
          <BackButton
            href="/forms"
            handleNavigationClick={handleNavigationClick}
          />

          <Typography variant="h1">Survey Answers</Typography>
        </div>

        <div className="w-full p-2 grid grid-cols-1 lg:grid-cols-2-2-1-1 gap-4 bg-white rounded-xl">
          <div className="w-full min-w-[300px]">
            <SearchField
              value={searchField}
              handleChange={handleSearchFieldChange}
              isFetching={isFetchingAnswers}
            />
          </div>

          <div className="w-full">
            <Pagination
              pagination={{
                currentPage: page,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                total,
              }}
              className="rounded-xl"
            />
          </div>

          <Button
            className="w-full h-full"
            variant="filled"
            color="primary"
            onClick={openQuestionsModal}
          >
            Manage displayed questions
          </Button>

          <Button
            className="w-full h-full"
            variant="filled"
            color="primary"
            onClick={handleDownloadClick}
            disabled={isFetchingAnswersLazy}
          >
            Download CSV
          </Button>
        </div>

        <VisualizationPanel
          form={form}
          displayedQuestions={displayedQuestions}
          answers={answers}
          deleteAnswer={deleteAnswer}
          setIsDeleteSurveyAnswerModalOpen={setIsDeleteSurveyAnswerModalOpen}
          setSelectedSurveyAnswer={setSelectedSurveyAnswer}
          onSurveyInitialized={setSurvey}
          getTabulatorSurveyData={getTabulatorSurveyData}
        />
      </div>

      <DeleteSurveyAnswerModal
        isOpen={isDeleteSurveyAnswerModalOpen}
        setIsOpen={setIsDeleteSurveyAnswerModalOpen}
        answer={selectedSurveyAnswer}
      />

      <DisplayedQuestionsModal
        open={isQuestionsModalOpen}
        toggleModal={toggleQuestionsModal}
        onClose={closeQuestionsModal}
        questions={formQuestions}
        displayedQuestions={displayedQuestions}
        onChange={setDisplayedQuestions}
      />
    </>
  );
};

export { SurveyAnswers };
