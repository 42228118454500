import clsx from 'clsx';
import { FC, PropsWithChildren, useEffect, useRef, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { match } from 'ts-pattern';

import {
  dayjs,
  formatDate,
  formatTicketDate,
  getFirstDayOfWeek,
  getWeekDays,
  not,
  showToastErrorMessage,
  truncateText,
} from 'src/shared/utils';
import { Card } from 'src/shared/ui/card';
import { Typography } from 'src/shared/ui/typography';
import { JobEntity, Role } from 'src/shared/types';
import { useAppDispatch } from 'src/store';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';
import { configActions, selectConfig } from 'src/store/slices/config';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/outlined/notifications/info.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/outlined/edit/copy.svg';
import { ReactComponent as ArrowCircleRight } from 'src/assets/icons/outlined/arrows/arrow-circle-right.svg';
import { ReactComponent as ArrowCircleLeft } from 'src/assets/icons/outlined/arrows/arrow-circle-left.svg';
import { ReactComponent as QuestionMarkIcon } from 'src/assets/icons/outlined/notifications/question-mark-circle.svg';
import { ReactComponent as AlertTriangleIcon } from 'src/assets/icons/outlined/notifications/alert-triangle.svg';
import { ReactComponent as ThumbUpIcon } from 'src/assets/icons/outlined/misc/thumb-up.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icon } from 'src/shared/ui/icon';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { useDeleteJobMutation, useGetEquipmentQuery, useGetPeopleQuery } from 'src/store/api';
import { CreateOrUpdateTicketModal } from 'src/shared/ui/modals/createOrUpdateTicket';
import { CreateOrUpdateJobModal } from 'src/shared/ui/modals/createOrUpdateJob/createOrUpdateJob';
import { CardSkeleton } from 'src/shared/ui/skeleton/ui/cardSkeleton';
import { Details, JobDetails } from 'src/shared/ui/details';
import { modalConfigActions, selectCurrentUser } from 'src/store/slices';
import { Skeleton } from 'src/shared/ui/skeleton';
import { Tag } from 'src/shared/ui/tag';
import { CopyJobModal } from 'src/shared/ui/modals/copyJob';
import { TicketCard } from 'src/shared/ui/ticketCard';

import { CardMenuItem } from './cardMenuItem';

type SchedulerCardProps = {
  date: string;
  className?: string;
  currentColumn: number;
  row?: number;
  jobs: JobEntity[];
} & JobEntity &
  PropsWithChildren;

const getCurrentHeight = (row: number) => {
  const rows = document.querySelectorAll(`.row-${row}`);

  let currentHeight = -1;

  rows.forEach((row) => {
    const elementHeight = row.clientHeight;

    if (elementHeight > currentHeight) {
      currentHeight = elementHeight;
    }
  });

  return currentHeight;
};

const SchedulerCard: FC<SchedulerCardProps> = ({
  date,
  className,
  currentColumn,
  row,
  jobs,
  ...job
}) => {
  const dispatch = useAppDispatch();

  const [deleteJob] = useDeleteJobMutation();
  const config = useSelector(selectConfig);
  const { listOfLoadingEntityIds, selectedWeek } = config;
  const rowSettings = config.weeks[selectedWeek].rows[row || 0];
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const cardContentRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] = useState(false);
  const [isUpdateOrderModalOpen, setIsUpdateOrderModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isCopyJobModalOpen, setIsCopyJobModalOpen] = useState(false);

  const { data: peopleData } = useGetPeopleQuery({});
  const { data: equipmentData } = useGetEquipmentQuery({});

  const { requestedStartDate, requestedCompleteDate } = job;

  const weekDays = getWeekDays(getFirstDayOfWeek(date)).map((day) => formatDate(day));

  const ticketsForToday = job.tickets
    .filter((ticket) => formatTicketDate(ticket.startTime) === formatDate(date))
    .sort((a, b) => a.projectName.localeCompare(b.projectName));

  const ticketsForThisWeek = job.tickets.filter((ticket) =>
    weekDays.includes(formatTicketDate(ticket.startTime)),
  );

  const startDate = dayjs(job.requestedStartDate).utc().format('YYYY-MM-DD');
  const endDate = dayjs(job.requestedCompleteDate).utc().format('YYYY-MM-DD');

  const continuesNextWeek = endDate > weekDays[weekDays.length - 1];
  const startedOnPreviousWeek = startDate < weekDays[0] && !weekDays.includes(startDate);

  const currentHeight = getCurrentHeight(Number(row));

  const formattedDate = dayjs(date).utc().format('YYYY-MM-DD');

  const isStartDate = startDate === formattedDate;
  const isEndDate = endDate === formattedDate;

  // | Render title as first card if content of the card split by weeks.
  const shouldRenderTitleAsFirstCard = isStartDate || (currentColumn === 1 && not(isStartDate));

  const isJobLoading = listOfLoadingEntityIds.includes(job.id);

  const ownerContent = (
    <div className="flex w-full h-max justify-between items-center">
      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {isJobLoading ? 'Pending' : job?.owner?.OwnerName}
      </Typography>

      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {isJobLoading ? 'Pending' : job?.ownerLocation?.OwnerLocation}
      </Typography>
    </div>
  );

  const tooltipInfo = shouldRenderTitleAsFirstCard ? undefined : (
    <div className="px-0.5">
      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {`Owner Name: ${job?.owner?.OwnerName || ''}`}
      </Typography>

      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {`Owner Location: ${job?.ownerLocation?.OwnerLocation || ''}`}
      </Typography>

      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {`Job: ${job?.workRequestNotes || ''}`}
      </Typography>

      <Typography
        className="text-textColor-tertiary"
        variant="c2"
      >
        {`Job Number: ${job?.sro || ''}`}
      </Typography>
    </div>
  );

  const jobHealthIcon = match(job.jobHealth)
    .when(
      (health) => not(health),
      () => <QuestionMarkIcon />,
    )
    .when(
      (health) => health && health < 50,
      () => <AlertTriangleIcon />,
    )
    .when(
      (health) => health && health < 100,
      () => <InfoIcon />,
    )
    .when(
      (health) => health && health >= 100,
      () => <ThumbUpIcon />,
    )
    .otherwise(() => <QuestionMarkIcon />);

  const jobHealthIconClassName = match(job.jobHealth)
    .when(
      (health) => not(health),
      () => '',
    )
    .when(
      (health) => health && health < 50,
      () => 'fill-semanticColor-danger',
    )
    .when(
      (health) => health && health < 100,
      () => `fill-semanticColor-warning`,
    )
    .when(
      (health) => health && health >= 100,
      () => 'fill-white',
    )
    .otherwise(() => '');

  const jobHealthTooltipText = match(job.jobHealth)
    .when(
      (health) => not(health),
      () => '',
    )
    .when(
      (health) => health && health < 50,
      () => 'Job health is bad',
    )
    .when(
      (health) => health && health < 100,
      () => `There're some problems with Job health`,
    )
    .when(
      (health) => health && health >= 100,
      () => 'Job health is in a good condition',
    )
    .otherwise(() => '');

  const openEditJobModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsUpdateOrderModalOpen(true);
  };

  const openCopyJobModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsCopyJobModalOpen(true);
  };

  const openCreateTicketModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsCreateTicketModalOpen(true);
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const handleJobDelete = async () => {
    try {
      await deleteJob(job.id).unwrap();
    } catch {
      const deleteErrorMessage = job.tickets.length
        ? `Can not delete Job with tickets`
        : `Sorry, an error occurred, when you tried to delete a job, please check your internet connection`;

      showToastErrorMessage(deleteErrorMessage);
    }
  };

  const openJobDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDetailsOpen(true);
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const handleAccordionChange = (isOpen: boolean) => {
    if (!ticketsForThisWeek.length) return;

    dispatch(
      configActions.changeRowOptions({
        selectedWeek,
        row: row || 0,
        options: {
          height: getCurrentHeight(Number(row)),
          isOpen: !isOpen,
        },
      }),
    );

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const jobActionButtons: DropDownItem[] = [
    {
      value: 'edit',
      label: (
        <CardMenuItem
          onClick={openEditJobModal}
          startIcon={
            <Icon
              className="fill-textColor-tertiary"
              icon={<EditIcon />}
            />
          }
          titleClassName="text-textColor-tertiary"
        >
          Edit
        </CardMenuItem>
      ),
    },
    {
      value: 'copy',
      label: (
        <CardMenuItem
          onClick={openCopyJobModal}
          startIcon={
            <Icon
              className="fill-textColor-tertiary"
              icon={<CopyIcon />}
            />
          }
          titleClassName="text-textColor-tertiary"
        >
          Copy
        </CardMenuItem>
      ),
    },
    not(job.tickets.length)
      ? {
          value: 'delete',
          label: (
            <CardMenuItem
              onClick={handleJobDelete}
              startIcon={
                <Icon
                  className="fill-semanticColor-danger"
                  icon={<TrashIcon />}
                />
              }
              titleClassName="text-semanticColor-danger"
            >
              Delete
            </CardMenuItem>
          ),
        }
      : null,
  ].filter(Boolean) as DropDownItem[];

  const renderTicketsCount = (isExtended = false) => {
    if (!ticketsForToday.length) {
      return null;
    }

    return (
      <>
        <Tag
          type="light"
          className="px-1 py-0.5 text-sm"
        >
          {ticketsForToday.length}
        </Tag>

        {isExtended && (
          <Typography
            variant="p2"
            className="text-textColor-tertiary"
          >
            Ticket(s)
          </Typography>
        )}
      </>
    );
  };

  const titleContent = match(shouldRenderTitleAsFirstCard)
    .when(
      (isFirstCard) => isFirstCard && isJobLoading,
      () => <Skeleton className="w-[344px] h-[40px]" />,
    )
    .when(
      (isFirstCard) => isFirstCard && not(isJobLoading),
      () => (
        <div
          ref={titleRef}
          className="flex justify-between items-center w-full"
        >
          <div className="flex w-full justify-between items-center">
            {renderTicketsCount()}

            <div className="flex flex-col gap-y-1">
              <Typography
                variant="c3"
                className={clsx(
                  'overflow-hidden text-ellipsis whitespace-nowrap',
                  continuesNextWeek || startedOnPreviousWeek ? 'max-w-[156px]' : 'max-w-[186px]',
                  continuesNextWeek && startedOnPreviousWeek && 'max-w-[126px]',
                )}
              >
                {job.workRequestNotes}
              </Typography>

              <div data-tooltip-id={`job-sro-${job.id}`}>
                <Typography
                  variant="c1"
                  className="text-textColor-secondary"
                >
                  {`Job Number: ${truncateText(job.sro, 7) || 'Pending'}`}
                </Typography>
              </div>
            </div>

            <div className="flex gap-2.5">
              {!!job.jobHealth && (
                <IconButton
                  color="basic"
                  size="none"
                  iconSize="md"
                  iconClassName={jobHealthIconClassName}
                  data-tooltip-id={`job-health-${job.id}`}
                  onClick={(event) => event.stopPropagation()}
                >
                  {jobHealthIcon}
                </IconButton>
              )}

              {startedOnPreviousWeek && (
                <IconButton
                  color="basic"
                  size="none"
                  iconSize="md"
                  iconClassName="fill-textColor-tertiary opacity-50"
                  data-tooltip-id="started-previous-week"
                  onClick={(event) => event.stopPropagation()}
                >
                  <ArrowCircleLeft />
                </IconButton>
              )}

              {continuesNextWeek && (
                <IconButton
                  color="basic"
                  size="none"
                  iconSize="md"
                  iconClassName="fill-textColor-tertiary opacity-50"
                  data-tooltip-id="continues-next-week"
                  onClick={(event) => event.stopPropagation()}
                >
                  <ArrowCircleRight />
                </IconButton>
              )}

              <IconButton
                color="basic"
                size="none"
                iconSize="md"
                className="bg-bgColor-card"
                iconClassName="fill-textColor-tertiary"
                onClick={openJobDetails}
              >
                <InfoIcon />
              </IconButton>

              {not(isReadonly) && (
                <DropDown
                  options={{ placement: 'bottom-start' }}
                  config={{
                    itemsElementClassName: clsx(
                      'shadow-[0px_2px_66px_-10px_#0000000F]',
                      'rounded-lg',
                      'bg-bgColor-card',
                    ),
                  }}
                  renderElement={() => (
                    <IconButton
                      color="basic"
                      size="none"
                      iconSize="md"
                      iconClassName="fill-textColor-tertiary"
                      onClick={() =>
                        dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''))
                      }
                    >
                      <MoreVerticalIcon />
                    </IconButton>
                  )}
                  items={jobActionButtons}
                />
              )}

              {not(isReadonly) && (
                <IconButton
                  color="basic"
                  size="none"
                  iconSize="md"
                  iconClassName="fill-textColor-tertiary"
                  onClick={openCreateTicketModal}
                >
                  <PlusIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      ),
    )
    .otherwise(() => (
      <div
        ref={titleRef}
        className="flex justify-between items-center w-full"
      >
        {isJobLoading ? (
          <Skeleton className="w-[344px] h-[40px]" />
        ) : (
          <div className="flex flex-1 items-center gap-x-2">
            {renderTicketsCount(true)}

            <div className="h-[40px] flex flex-1 justify-end items-center">
              {not(isReadonly) && (
                <IconButton
                  color="basic"
                  size="none"
                  iconSize="md"
                  iconClassName="fill-textColor-tertiary"
                  onClick={openCreateTicketModal}
                >
                  <PlusIcon />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    ));

  useEffect(() => {
    if (!cardContentRef.current) return;

    const resizeContentObserver = new ResizeObserver(() => {
      dispatch(
        schedulerBoardSettingsActions.addCardHeight({
          id: job.id,
          item: {
            date,
            height: currentHeight,
          },
        }),
      );
    });
    resizeContentObserver.observe(cardContentRef.current);

    // eslint-disable-next-line consistent-return
    return () => resizeContentObserver.disconnect();
  }, [
    job.id,
    cardContentRef.current?.offsetHeight,
    date,
    dispatch,
    titleRef.current?.offsetHeight,
    currentHeight,
  ]);

  useEffect(() => {
    dispatch(
      configActions.changeRowOptions({
        selectedWeek,
        row: row || 0,
        options: {
          height: getCurrentHeight(Number(row)),
          isOpen: rowSettings ? rowSettings.isOpen : false,
        },
      }),
    );
  }, [
    dispatch,
    job.id,
    row,
    ticketsForThisWeek.length,
    jobs.length,
    selectedWeek,
    peopleData?.length,
    equipmentData?.length,
    jobs,
    rowSettings,
  ]);

  return (
    <>
      <Card
        hideIcon={!shouldRenderTitleAsFirstCard || !ticketsForThisWeek.length}
        isAccordion
        handleAccordionChange={handleAccordionChange}
        // | If this card is part of multiple cards, then use the isOpenMultiple state
        // | To correctly manage isOpen state.
        open={rowSettings?.isOpen}
        iconSize="none"
        iconPosition="left"
        title={titleContent}
        preTitle={ownerContent || ' '}
        shouldRenderTitleAsFirstCard={shouldRenderTitleAsFirstCard}
        tooltipInfo={shouldRenderTitleAsFirstCard ? undefined : tooltipInfo}
        className={clsx(
          'rounded-none',
          {
            'rounded-tl-2xl':
              isStartDate || (currentColumn === 1 && not(dayjs(requestedStartDate).isSame(date))),
            'rounded-bl-2xl':
              isStartDate || (currentColumn === 1 && not(dayjs(requestedStartDate).isSame(date))),
            'rounded-tr-2xl':
              isEndDate || (currentColumn === 7 && not(dayjs(requestedCompleteDate).isSame(date))),
            'rounded-br-2xl':
              isEndDate || (currentColumn === 7 && not(dayjs(requestedCompleteDate).isSame(date))),
          },
          'border-none',
          className,
        )}
        style={{
          minHeight: 'inherit',
        }}
      >
        {!!ticketsForToday.length && (
          <div
            ref={cardContentRef}
            className="flex flex-col gap-y-6"
          >
            {ticketsForToday.map((ticket) => {
              if (listOfLoadingEntityIds.includes(ticket.id) || not(ticket.ticketNumber)) {
                return (
                  <CardSkeleton
                    key={ticket.id}
                    className="rounded-l-none pt-0"
                    size={2}
                    isMainCard={false}
                  />
                );
              }

              return (
                <TicketCard
                  key={ticket.id}
                  ticket={ticket}
                  job={job}
                />
              );
            })}
          </div>
        )}
      </Card>

      <CreateOrUpdateTicketModal
        type="create"
        isOpen={isCreateTicketModalOpen}
        setIsOpen={setIsCreateTicketModalOpen}
        job={job}
        date={date}
      />

      <CreateOrUpdateJobModal
        type="update"
        isOpen={isUpdateOrderModalOpen}
        setIsOpen={setIsUpdateOrderModalOpen}
        job={{
          row,
          ...job,
        }}
      />

      <CopyJobModal
        isOpen={isCopyJobModalOpen}
        setIsOpen={setIsCopyJobModalOpen}
        job={job}
      />

      <Details
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        setIsEditMenuOpen={setIsUpdateOrderModalOpen}
        title={job.workRequestNotes}
        hasOutsideClick
      >
        <JobDetails job={job} />
      </Details>

      {continuesNextWeek && (
        <Tooltip
          id="continues-next-week"
          place="right"
          content="This job continues on the next week"
          className="max-w-[150px] z-[1] bg-[rgba(0,0,0,0.7)]"
        />
      )}

      {startedOnPreviousWeek && (
        <Tooltip
          id="started-previous-week"
          place="right"
          content="This job has started on the previous week"
          className="max-w-[150px] z-[1] bg-[rgba(0,0,0,0.7)]"
        />
      )}

      {!!job.jobHealth && (
        <Tooltip
          id={`job-health-${job.id}`}
          place="bottom"
          content={jobHealthTooltipText}
          className="max-w-fit z-[1] bg-[rgba(0,0,0,0.7)]"
        />
      )}

      {job.sro && (
        <Tooltip
          id={`job-sro-${job.id}`}
          place="bottom"
          content={`Job Number: ${job.sro}`}
          className="max-w-fit z-[1] bg-[rgba(0,0,0,0.7)]"
        />
      )}
    </>
  );
};

export { SchedulerCard };
export type { SchedulerCardProps };
