import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  useGetSurveyQuery,
  useEditSurveyAnswerMutation,
  useGetSurveyAnswerQuery,
} from 'src/store/api';
import '../SurveyForm/style.css';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';

import { navigationCSS } from '../SurveyForm/utils';
import { SurveyNavigation } from '../SurveyForm/Components/SurveyNavigation';

const EditSurveyAnswer = () => {
  const { formId, answerId } = useParams();

  const navigate = useNavigate();

  const { data: form, isFetching: isLoadingForm } = useGetSurveyQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const { data: answer, isFetching: isLoadingAnswer } = useGetSurveyAnswerQuery(answerId || '', {
    skip: !answerId,
  });

  const isLoading = isLoadingForm || isLoadingAnswer;

  const [editSurveyAnswer, { isLoading: isSaving }] = useEditSurveyAnswerMutation();

  const [page, setPage] = useState(0);
  const [survey, setSurvey] = useState<Model>(new Model({}));

  const navigationHref = `/forms/${formId}/answers`;

  useEffect(() => {
    if (not(answer)) {
      showToastErrorMessage('Answer not found or not accessible');

      navigate('/forms');
    }
  }, [answer, navigate]);

  useEffect(() => {
    if (form) {
      setSurvey(new Model(form.content));
    }
  }, [form]);

  useEffect(() => {
    survey.onComplete.add(async (sender) => {
      if (not(survey)) return;

      try {
        await editSurveyAnswer({
          surveyId: formId || '',
          answerId: answerId || '',
          answer: sender.data,
        }).unwrap();
      } catch (error) {
        showToastErrorMessage('There was an error trying to save the results of the filled form');
      } finally {
        navigate(navigationHref);
      }
    });

    return () => {
      survey.onComplete.clear();
    };
  }, [form, survey, editSurveyAnswer, navigate, navigationHref, answerId, formId]);

  useEffect(() => {
    survey.data = answer ? answer.answer : {};
  }, [answer, survey]);

  if (isLoading) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading form..."
      />
    );
  }

  if (not(form)) {
    navigate(navigationHref);
    showToastErrorMessage('There was an error trying to load the form');

    return null;
  }

  survey.onCurrentPageChanged.add((_, options) => {
    setPage(options.newCurrentPage.visibleIndex);
  });

  survey.css = navigationCSS;

  return (
    <>
      {isSaving && (
        <Spinner
          withBackdrop
          fallbackText="Saving results of the filled form..."
        />
      )}

      <div className="flex flex-col gap-y-4 mt-4 w-full">
        <SurveyNavigation
          form={form}
          survey={survey}
          page={page}
          withCompleteButton
          backButtonHref={navigationHref}
          hiddenClearFormButton
        />

        <Survey
          currentPageNo={page}
          model={survey}
        />
      </div>
    </>
  );
};

export { EditSurveyAnswer };
