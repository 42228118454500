import { useState } from 'react';

import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';
import {
  useGetSurveyNotificationsQuery,
  useGetSurveyNotificationTemplatesQuery,
} from 'src/store/api/survey-notification';
import { useGetAuthPeopleQuery } from 'src/store/api';
import { ReactComponent as PlusIcon } from 'src/assets/icons/outlined/edit/plus.svg';
import { Spinner } from 'src/shared/ui/spinner';

import { CreateOrUpdateSurveyNotificationModal, NotificationCard } from './Features';

const Notifications = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data: notifications = [], isLoading: isLoadingNotifications } =
    useGetSurveyNotificationsQuery('');
  const { data: authPeople = [] } = useGetAuthPeopleQuery('');
  const { data: templates = [], isLoading: isLoadingTemplates } =
    useGetSurveyNotificationTemplatesQuery('');

  const isDataLoading = isLoadingNotifications || isLoadingTemplates;

  if (isDataLoading) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading table data, please wait..."
      />
    );
  }

  return (
    <div className="flex flex-col gap-y-6 w-[1000px] p-6">
      <div className="flex justify-between w-full flex-wrap gap-1">
        <Typography variant="h1">Notifications</Typography>

        <Button
          color="primary"
          size="lg"
          endIcon={<PlusIcon />}
          className="p-[12px] sm:pr-1"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <span className="hidden sm:flex">Create Notification</span>
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
        {notifications.map((notification) => {
          const template = templates.find(
            (template) => template.id === notification.emailTemplateId,
          );

          if (!template) {
            return null;
          }

          return (
            <NotificationCard
              key={notification.id}
              notification={notification}
              authPeople={authPeople}
              template={template}
            />
          );
        })}
      </div>

      {isCreateModalOpen && (
        <CreateOrUpdateSurveyNotificationModal
          isOpen={isCreateModalOpen}
          setIsOpen={setIsCreateModalOpen}
        />
      )}
    </div>
  );
};

export { Notifications };
