import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Typography } from 'src/shared/ui/typography';
import { RootState } from 'src/store';
import { TimePeriod } from 'src/shared/types';
import { dayjs, formatDate, getCurrentDay } from 'src/shared/utils';
import { useScrollToToday } from 'src/shared/hooks/useScrollToToday';

import {
  DATES_TOP_SPACE,
  DATES_VERTICAL_GAP,
  DAY_TITLE_HEIGHT,
  getHeaderDayHours,
  HEADER_HEIGHT,
  HOURS_IN_COLUMN,
  ONE_HOUR_DAY_WIDTH,
  ONE_HOUR_WIDTH,
  ZOOM_VALUES,
} from '../../helpers';

dayjs.extend(customParseFormat);

interface OneDaySectorProps {
  index: number;
  date: string;
  zoomValueIndex: number;
}

const OneDaySector: FC<OneDaySectorProps> = ({ index, date, zoomValueIndex }) => {
  const { ref: scrollToTodayRef } = useScrollToToday(true);
  const currentDay = formatDate(getCurrentDay());
  const dateWithYear = `${date}, ${dayjs().format('YYYY')}`;
  const isSameDay = dayjs(currentDay).isSame(formatDate(dateWithYear));

  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const columnWidth = useMemo(() => {
    return isDayPeriod ? ONE_HOUR_DAY_WIDTH : HOURS_IN_COLUMN * ONE_HOUR_WIDTH;
  }, [isDayPeriod]);

  const dayHours = getHeaderDayHours(isDayPeriod);
  const isFirstCard = index === 0;

  const topSpace = HEADER_HEIGHT + DATES_TOP_SPACE + DATES_VERTICAL_GAP + DAY_TITLE_HEIGHT;

  return (
    <div
      className="flex flex-col"
      style={{
        gap: `${DATES_VERTICAL_GAP}px`,
      }}
      ref={isSameDay ? scrollToTodayRef : undefined}
    >
      <Typography variant="h3">{date}</Typography>

      <ul className="flex">
        {dayHours.map((hour, i) => {
          const hasLeftBorder = i === 0 && isFirstCard;
          return (
            <li
              key={hour}
              style={{
                width: `${
                  !zoomValueIndex ? columnWidth : columnWidth * ZOOM_VALUES[zoomValueIndex]
                }px`,
                height: `calc(100vh - ${topSpace}px)`,
              }}
              className={clsx('text-left pl-0.5 border-r border-r-textColor-hint', {
                'border-l border-l-textColor-hint': hasLeftBorder,
              })}
            >
              <Typography
                variant="c2"
                className="text-xs leading-[18px]"
              >
                {`${hour}${isDayPeriod || zoomValueIndex ? '' : ':00'}`}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { OneDaySector };
