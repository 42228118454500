import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useGetActivitiesByProviderTeamIdQuery } from 'src/store/api/activities';
import { dayjs } from 'src/shared/utils';
import { RootState } from 'src/store';
import { ActivityJob, SearchableTAFilters } from 'src/shared/types';

import {
  getFormattedActivities,
  getOverlappedCards,
  POLLING_INTERVAL_ACTIVITIES,
} from '../../helpers';

import { ActivityRow } from './ActivityRow';

interface ActivityJobProcessProps {
  activityId: string;
  processIndex: number;
  setContainerWidth: (width?: number) => void;
  timeZone: string;
  zoomValueIndex: number;
}

const ActivityJobProcess: FC<ActivityJobProcessProps> = ({
  activityId,
  processIndex,
  setContainerWidth,
  timeZone,
  zoomValueIndex,
}) => {
  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const [searchParams] = useSearchParams();

  const jobIdSearch = useMemo(
    () => searchParams.get(SearchableTAFilters.JobID) || undefined,
    [searchParams],
  );

  const { endDate, startDate } = useMemo(() => {
    const dates = datesState.map((date) => dayjs.utc(date).tz(timeZone));

    const startDate = dayjs(dates[0]).set('hour', 0).set('minute', 0).set('second', 0).toDate();
    const endDate = dayjs(dates[dates.length - 1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .toDate();

    return {
      startDate,
      endDate,
    };
  }, [datesState, timeZone]);

  const { data: teamActivitiesData } = useGetActivitiesByProviderTeamIdQuery(
    {
      id: activityId,
      startDate: dayjs.utc(startDate).toISOString(),
      endDate: dayjs.utc(endDate).toISOString(),
      jobIdSearch,
      ...boardFilters,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL_ACTIVITIES,
    },
  );

  const cardsList = useMemo(() => {
    return teamActivitiesData ? getFormattedActivities(teamActivitiesData.activity, timeZone) : [];
  }, [teamActivitiesData, timeZone]);

  const { filteredCards: mainRowCards, overlappedCards } = useMemo(() => {
    if (!cardsList.length) {
      return {
        filteredCards: [],
        overlappedCards: [],
      };
    }

    const { overlappedCards, filteredCards } = getOverlappedCards(cardsList);

    return {
      filteredCards,
      overlappedCards,
    };
  }, [cardsList]);

  const { cardsOverlappedList, overLappedCardsIndexes } = useMemo(() => {
    const cardsOverlappedList = [] as ActivityJob[][];

    if (!overlappedCards.length) {
      return { cardsOverlappedList, overLappedCardsIndexes: [] };
    }

    const { overlappedCards: innerOverlapped, filteredCards: innerFilteredCards } =
      getOverlappedCards(overlappedCards);

    if (!innerFilteredCards.length) {
      return { cardsOverlappedList, overLappedCardsIndexes: [] };
    }

    cardsOverlappedList.push(innerFilteredCards);

    let restOfOverlappedCards = innerOverlapped;

    while (restOfOverlappedCards.length > 0) {
      const { overlappedCards: doublesInner, filteredCards: innerFilteredCardsInner } =
        getOverlappedCards(restOfOverlappedCards);
      cardsOverlappedList.push(innerFilteredCardsInner);

      restOfOverlappedCards = doublesInner;
    }

    const overLappedCardsIndexes = cardsOverlappedList.map((innerArr) => innerArr[0].id);

    return { cardsOverlappedList, overLappedCardsIndexes };
  }, [overlappedCards]);

  return (
    <>
      <ActivityRow
        cards={mainRowCards}
        startDate={startDate}
        timeZone={timeZone}
        setContainerWidth={setContainerWidth}
        processIndex={processIndex}
        zoomValueIndex={zoomValueIndex}
      />

      {!!cardsOverlappedList.length &&
        cardsOverlappedList.map((overlappedCards, index) => {
          return (
            <ActivityRow
              key={overLappedCardsIndexes[index]}
              cards={overlappedCards}
              startDate={startDate}
              timeZone={timeZone}
              setContainerWidth={setContainerWidth}
              processIndex={processIndex}
              isOverlapped
              indexOverLapped={index}
              zoomValueIndex={zoomValueIndex}
            />
          );
        })}
    </>
  );
};

export { ActivityJobProcess };
