import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActivityJob } from 'src/shared/types';
import { RootState } from 'src/store';

import { AddUpdateModal } from '../addUpdateModal';
import {
  CARD_SPREAD_BUTTON_RADIUS,
  HOURS_IN_DAY,
  ONE_HOUR_DAY_WIDTH,
  ONE_HOUR_DAY_WIDTH_BY_MINUTES,
  ONE_HOUR_WIDTH,
  ONE_HOUR_WIDTH_BY_MINUTES,
  ZOOM_VALUES,
} from '../../helpers';
import { UpdatesListModal } from '../updatesListModal';

import { ConnectionLine } from './ConnectionLine';
import { CardContainer } from './CardContainer';

interface ActivityJobCardProps {
  card: ActivityJob;
  index: number;
  processIndex: number;
  previousDate: Date;
  updateCardSpreadList: () => void;
  setContainerWidth: (width?: number) => void;
  hasPrevCardSpread: boolean;
  hasCardSpread: boolean;
  timeZone: string;
  datesStart: Date;
  isOverlapped?: boolean;
  indexOverLapped?: number;
  zoomValueIndex: number;
}

const ActivityJobCard: FC<ActivityJobCardProps> = ({
  card,
  index,
  processIndex,
  previousDate,
  updateCardSpreadList,
  setContainerWidth,
  hasPrevCardSpread,
  hasCardSpread,
  timeZone,
  datesStart,
  isOverlapped,
  indexOverLapped,
  zoomValueIndex,
}) => {
  const [minCardWidth, setMinCardWidth] = useState(0);

  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);

  const isDayPeriod = useMemo(() => filterPeriod === 'day', [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs.utc(date).tz(timeZone)),
    [datesState, timeZone],
  );

  const startDate = dayjs(dates[0]).set('hour', 0).set('minute', 0).set('second', 0).toDate();
  const endDate = dayjs(dates[isDayPeriod ? 0 : dates.length - 1])
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .toDate();

  const endBySchedule = useMemo(
    () => dayjs.utc(endDate).tz(timeZone).toDate(),
    [endDate, timeZone],
  );

  const startBySchedule = useMemo(
    () => dayjs.utc(startDate).tz(timeZone).toDate(),
    [startDate, timeZone],
  );

  const cardWidth = useMemo(() => {
    const endTime = dayjs(card.actualEnd || card.endWork).isAfter(endBySchedule)
      ? endBySchedule
      : card.actualEnd || card.endWork;
    const startTime = dayjs(card.actualStart || card.startWork).isBefore(startBySchedule)
      ? startBySchedule
      : card.actualStart || card.startWork;
    return (
      dayjs(endTime).diff(dayjs(startTime), 'minute') *
      (isDayPeriod ? ONE_HOUR_DAY_WIDTH_BY_MINUTES : ONE_HOUR_WIDTH_BY_MINUTES) *
      ZOOM_VALUES[zoomValueIndex]
    );
  }, [card, endBySchedule, startBySchedule, isDayPeriod, zoomValueIndex]);

  const hasMoreSpace = useMemo(() => minCardWidth > cardWidth, [minCardWidth, cardWidth]);

  const leftDistance = useMemo(() => {
    const startWork = card.actualStart || card.startWork;
    const correctedLeftButtonSpace = zoomValueIndex
      ? CARD_SPREAD_BUTTON_RADIUS * ZOOM_VALUES[zoomValueIndex]
      : CARD_SPREAD_BUTTON_RADIUS;

    const startTime = dayjs.utc(startWork).tz(timeZone).isBefore(startBySchedule)
      ? startBySchedule
      : startWork;

    return (
      dayjs.utc(startTime).tz(timeZone).diff(dayjs.utc(previousDate).tz(timeZone), 'minute') *
        (isDayPeriod
          ? ONE_HOUR_DAY_WIDTH_BY_MINUTES
          : ONE_HOUR_WIDTH_BY_MINUTES * ZOOM_VALUES[zoomValueIndex]) -
      (hasPrevCardSpread ? correctedLeftButtonSpace : 0)
    );
  }, [
    previousDate,
    card,
    hasPrevCardSpread,
    timeZone,
    startBySchedule,
    isDayPeriod,
    zoomValueIndex,
  ]);

  const distanceFromStart = useMemo(() => {
    const startWork = card.actualStart || card.startWork;

    const startTime = dayjs.utc(startWork).tz(timeZone).isBefore(startBySchedule)
      ? startBySchedule
      : startWork;

    return (
      dayjs.utc(startTime).tz(timeZone).diff(dayjs.utc(datesStart).tz(timeZone), 'minute') *
      (isDayPeriod
        ? ONE_HOUR_DAY_WIDTH_BY_MINUTES
        : ONE_HOUR_WIDTH_BY_MINUTES * ZOOM_VALUES[zoomValueIndex])
    );
  }, [card, datesStart, timeZone, startBySchedule, isDayPeriod, zoomValueIndex]);

  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);
  const [isUpdatesListOpen, setIsUpdatesListOpen] = useState(false);

  const handleAddUpdateClick = () => {
    setIsAddUpdateModalOpen((prev) => !prev);
  };

  const handleSetIsUpdatesListOpen = () => {
    setIsUpdatesListOpen((prev) => !prev);
  };

  const [isWideCard, setIsWideCard] = useState(false);

  const handleSpreadCard = () => setIsWideCard((prev) => !prev);

  useEffect(() => {
    if (hasMoreSpace && !hasCardSpread) {
      updateCardSpreadList();
    }
  }, [hasMoreSpace, hasCardSpread, updateCardSpreadList]);

  const cardMarginLeft = useMemo(() => {
    if (index === 0) {
      return '0px';
    }

    if (leftDistance <= 0 && hasPrevCardSpread && !zoomValueIndex) {
      return `${-CARD_SPREAD_BUTTON_RADIUS}px`;
    }

    if (leftDistance <= 0 && hasPrevCardSpread && zoomValueIndex) {
      return `${-CARD_SPREAD_BUTTON_RADIUS * ZOOM_VALUES[zoomValueIndex]}px`;
    }

    if (leftDistance <= 0 && !hasPrevCardSpread) {
      return `0px`;
    }

    return leftDistance <= 0 ? `${-CARD_SPREAD_BUTTON_RADIUS}px` : '0px';
  }, [leftDistance, hasPrevCardSpread, index, zoomValueIndex]);

  const preparedDates = useMemo(() => {
    return isDayPeriod
      ? [dayjs(dates[0]).format('dddd, MMMM D')]
      : dates.map((date) => dayjs(date).format('dddd, MMMM D'));
  }, [dates, isDayPeriod]);

  const containerWidth = useMemo(() => {
    return (
      preparedDates.length *
      HOURS_IN_DAY *
      (isDayPeriod ? ONE_HOUR_DAY_WIDTH : ONE_HOUR_WIDTH * ZOOM_VALUES[zoomValueIndex])
    );
  }, [preparedDates, isDayPeriod, zoomValueIndex]);

  const totalWidth = useMemo(() => {
    return (
      distanceFromStart +
      minCardWidth +
      (hasCardSpread ? CARD_SPREAD_BUTTON_RADIUS : 0) +
      (hasPrevCardSpread ? CARD_SPREAD_BUTTON_RADIUS : 0)
    );
  }, [minCardWidth, distanceFromStart, hasCardSpread, hasPrevCardSpread]);

  const [shouldContainerGrow, setShouldContainerGrow] = useState(false);

  useEffect(() => {
    if (totalWidth > containerWidth && isWideCard) {
      setShouldContainerGrow(true);
      setContainerWidth(totalWidth);
    }

    if (shouldContainerGrow && totalWidth <= containerWidth) {
      setShouldContainerGrow(false);
      setContainerWidth();
    }
  }, [isWideCard, totalWidth, containerWidth, setContainerWidth, shouldContainerGrow]);

  const startTime = useMemo(() => {
    const startWork = card.actualStart || card.startWork;

    return dayjs.utc(startWork).tz(timeZone).isBefore(startBySchedule)
      ? startBySchedule
      : startWork;
  }, [card, startBySchedule, timeZone]);

  return (
    <>
      {leftDistance > 0 && (
        <ConnectionLine
          index={index}
          leftDistance={leftDistance}
          isOverlapped={isOverlapped}
        />
      )}

      <CardContainer
        card={card}
        cardMarginLeft={cardMarginLeft}
        cardWidth={cardWidth}
        hasMoreSpace={hasMoreSpace}
        isWideCard={isWideCard}
        handleSpreadCard={handleSpreadCard}
        setIsAddUpdateModalOpen={handleAddUpdateClick}
        setIsUpdatesListOpen={handleSetIsUpdatesListOpen}
        setMinCardWidthByContent={setMinCardWidth}
        timeZone={timeZone}
        zIndex={processIndex + index}
        minCardWidth={minCardWidth}
        isOverlapped={isOverlapped}
        indexOverLapped={indexOverLapped}
        startTime={startTime}
        zoomValueIndex={zoomValueIndex}
      />

      {isWideCard && (
        <div
          className="absolute top-0"
          style={{
            left: `${distanceFromStart}px`,
            width: `${minCardWidth}px`,
          }}
        >
          <CardContainer
            card={card}
            cardMarginLeft={cardMarginLeft}
            cardWidth={cardWidth}
            hasMoreSpace={hasMoreSpace}
            isWideCard={isWideCard}
            handleSpreadCard={handleSpreadCard}
            setIsAddUpdateModalOpen={handleAddUpdateClick}
            setIsUpdatesListOpen={handleSetIsUpdatesListOpen}
            setMinCardWidthByContent={setMinCardWidth}
            timeZone={timeZone}
            zIndex={processIndex + index}
            minCardWidth={minCardWidth}
            isAbsolute
            isOverlapped={isOverlapped}
            indexOverLapped={indexOverLapped}
            startTime={startTime}
            zoomValueIndex={zoomValueIndex}
          />
        </div>
      )}

      {isAddUpdateModalOpen && (
        <AddUpdateModal
          isOpen={isAddUpdateModalOpen}
          setIsOpen={setIsAddUpdateModalOpen}
          selectedJob={card}
          timeZone={timeZone}
        />
      )}

      {isUpdatesListOpen && (
        <UpdatesListModal
          isOpen={isUpdatesListOpen}
          setIsOpen={setIsUpdatesListOpen}
          selectedJobId={card.id}
          timeZone={timeZone}
        />
      )}
    </>
  );
};

export { ActivityJobCard };
