import { PropsWithChildren, forwardRef, HTMLAttributes, ReactNode } from 'react';
import { match } from 'ts-pattern';
import { styled, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

import { Accordion, AccordionProps } from 'src/shared/ui/accordion';
import { clsx } from 'src/shared/utils/clsx';

type CardProps = PropsWithChildren &
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
  AccordionProps & {
    isAccordion?: boolean;
    preTitle?: ReactNode;
    shouldRenderTitleAsFirstCard?: boolean;
    tooltipInfo?: ReactNode;
    isTicketCard?: boolean;
    accordionTitleRowClassname?: string;
  };

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      title,
      preTitle,
      shouldRenderTitleAsFirstCard,
      isTicketCard = false,
      tooltipInfo,
      iconPosition = 'right',
      iconSize,
      accordionTitleRowClassname,
      className,
      isAccordion = false,
      defaultOpen,
      ...props
    },
    ref,
  ) => {
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip
        {...props}
        classes={{ popper: className }}
      />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F6F9FD',
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        minWidth: '344px',
        border: tooltipInfo ? '1px solid #E0E0E0' : undefined,
      },
    }));

    const disabledTooltip = !!shouldRenderTitleAsFirstCard && isTicketCard;

    const content = match(isAccordion)
      .with(true && disabledTooltip, () => (
        <Accordion
          {...props}
          defaultOpen={defaultOpen}
          ref={ref}
          iconPosition={iconPosition}
          iconSize={iconSize}
          className={clsx(
            `flex flex-col p-4 gap-y-4 w-full border-[1px] justify-start bg-bgColor-card border-outlineColor-input-border rounded-2xl `,
            className,
          )}
          preTitle={preTitle}
          shouldRenderTitleAsFirstCard={shouldRenderTitleAsFirstCard}
          title={title}
          titleRowClassname={accordionTitleRowClassname}
        >
          {children}
        </Accordion>
      ))
      .with(true && !disabledTooltip, () => (
        <LightTooltip
          title={tooltipInfo}
          placement="top"
          sx={{
            '& .MuiTooltip-tooltip': {
              padding: 0,
              opacity: 1,
              borderRadius: '4px',
              backgroundColor: '#F6F9FD',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
            },
          }}
        >
          <Accordion
            {...props}
            defaultOpen={defaultOpen}
            ref={ref}
            iconPosition={iconPosition}
            iconSize={iconSize}
            className={clsx(
              `flex flex-col p-4 gap-y-4 w-full border-[1px] justify-start bg-bgColor-card border-outlineColor-input-border rounded-2xl `,
              className,
            )}
            preTitle={preTitle}
            shouldRenderTitleAsFirstCard={shouldRenderTitleAsFirstCard}
            title={title}
            titleRowClassname={accordionTitleRowClassname}
          >
            {children}
          </Accordion>
        </LightTooltip>
      ))
      .with(false, () => (
        <div
          {...props}
          ref={ref}
          className={clsx(
            `flex flex-col p-4 gap-y-4 w-full border-[1px] justify-start bg-bgColor-card border-outlineColor-input-border rounded-2xl `,
            className,
          )}
        >
          {title}

          {children}
        </div>
      ))
      .otherwise(() => null);

    return content;
  },
);

Card.displayName = 'Card';

export { Card };
export type { CardProps };
