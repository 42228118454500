import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from 'src/store';
import { TimePeriod } from 'src/shared/types';
import { DATE_FORMAT } from 'src/shared/utils';

import { OneDaySector } from '../oneDaySector';

interface ActivityGridTableProps {
  zoomValueIndex: number;
}

const ActivityGridTable: FC<ActivityGridTableProps> = ({ zoomValueIndex }) => {
  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs(date).format().toString()),
    [datesState],
  );

  const preparedDates = useMemo(() => {
    return isDayPeriod
      ? [dayjs(dates[0]).format(DATE_FORMAT.DAY_MONTH_DATE)]
      : dates.map((date) => {
          return zoomValueIndex
            ? dayjs(date).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE)
            : dayjs(date).format(DATE_FORMAT.DAY_MONTH_DATE);
        });
  }, [dates, isDayPeriod, zoomValueIndex]);

  return (
    <>
      {preparedDates.map((date, index) => (
        <OneDaySector
          key={date}
          index={index}
          date={date}
          zoomValueIndex={zoomValueIndex}
        />
      ))}
    </>
  );
};

export { ActivityGridTable };
