/* eslint-disable react/no-unstable-nested-components */
import { Dispatch, FC, SetStateAction } from 'react';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { ReactComponent as EmptySquareIcon } from 'src/assets/icons/outlined/edit/square.svg';
import { ReactComponent as CheckedSquareIcon } from 'src/assets/icons/filled/edit/checkmark-square.svg';
import { clsx } from 'src/shared/utils/clsx';
import { IconButton } from 'src/shared/ui/iconButton';
import { Datepicker } from 'src/shared/ui/datepicker';
import { dayjs, getDatesBetween, not, showToastErrorMessage } from 'src/shared/utils';
import { Error, JobEntity, TicketEntity } from 'src/shared/types';
import { useCopyTicketMutation } from 'src/store/api';
import { useAppDispatch } from 'src/store';
import { configActions } from 'src/store/slices';

import { DateRangeType } from '../datepicker/types';

type InitialValues = {
  dateRange: DateRangeType;
  keepAssignment: boolean;
};

const initialValues: InitialValues = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
  keepAssignment: true,
};

type CopyTicketModalProps = {
  isOpen: boolean;
  ticket: TicketEntity;
  job: JobEntity;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const CopyTicketModal: FC<CopyTicketModalProps> = ({ isOpen = false, ticket, job, setIsOpen }) => {
  const dispatch = useAppDispatch();

  const [copyTicket] = useCopyTicketMutation();

  const toggleCopyTicketModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const onSubmit = async (values: InitialValues) => {
    toggleCopyTicketModal(isOpen);

    const dates = getDatesBetween(values.dateRange);

    const ticketIds = dates.map(() => uuidv4());

    try {
      dispatch(configActions.addLoadingEntityIds(ticketIds));

      await copyTicket({
        ticketSourceId: ticket.id,
        startDate: dayjs(values.dateRange.startDate).utc().format(),
        endDate: dayjs(values.dateRange.endDate).utc().format(),
        keepAssignments: values.keepAssignment,
      }).unwrap();
    } catch (error: any) {
      let message = '';

      if (error?.data?.message as string) {
        message = error.data.message;
      }

      const errorText =
        (error as Error).status === 400
          ? `${message || 'Cannot copy Ticket(s) due to overlapping timeframe.'}`
          : 'Sorry, an error occurred, when you tried to copy a ticket';

      showToastErrorMessage(errorText);
    } finally {
      dispatch(configActions.removeLoadingEntityIds(ticketIds));
    }
  };

  const Schema = z.object({
    dateRange: z.object({
      startDate: z.custom(
        (value) => {
          const isBetween = dayjs(value as string)
            .utc()
            .isBetween(
              dayjs(job?.requestedStartDate),
              dayjs(job?.requestedCompleteDate),
              'day',
              '[]',
            );

          return isBetween;
        },
        () => {
          return {
            message: `The date range entered exceeds the dates of the selected work job (${dayjs(
              job?.requestedStartDate,
            )
              .utc()
              .format('MMM D')} - ${dayjs(job?.requestedCompleteDate)
              .utc()
              .format('MMM D')}). Please edit the work job dates or adjust the date of the ticket.`,
          };
        },
      ),
      endDate: z.custom(
        (value) => {
          const isBetween = dayjs(value as string)
            .utc()
            .isBetween(
              dayjs(job?.requestedStartDate),
              dayjs(job?.requestedCompleteDate),
              'day',
              '[]',
            );

          return isBetween;
        },
        () => {
          return {
            message: `The date range entered exceeds the dates of the selected work job (${dayjs(
              job?.requestedStartDate,
            ).format('MMM D')} - ${dayjs(job?.requestedCompleteDate).format(
              'MMM D',
            )}). Please edit the work job dates or adjust the date of the ticket.`,
          };
        },
      ),
    }),
  });

  const { values, handleChange, handleSubmit, errors, setFieldValue } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(Schema),
    initialValues,
    enableReinitialize: true,
  });

  const isSaveDisabled = Boolean(
    !values.dateRange?.startDate ||
      !values.dateRange?.endDate ||
      errors.dateRange?.startDate ||
      errors.dateRange?.endDate,
  );

  const clearDateRange = () => {
    setFieldValue('dateRange', {
      startDate: null,
      endDate: null,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleCopyTicketModal}
    >
      <form
        className="flex flex-col gap-y-6"
        onSubmit={handleSubmit}
      >
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Copy Ticket
        </Typography>

        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <Typography variant="label">Copy to Date(s)</Typography>

            <Datepicker
              error={errors.dateRange?.startDate || errors.dateRange?.endDate}
              useRange={false}
              placeholder="Select date(s)"
              value={values.dateRange}
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'dateRange',
                    value,
                  },
                })
              }
              displayFormat="dddd, DD MMMM"
              separator="-"
              toggleClassName={clsx(
                values.dateRange?.startDate && values.dateRange?.endDate
                  ? 'absolute right-[12px] top-[12px] bg-transparent'
                  : 'absolute left-[16px] top-[12px] bg-transparent',
              )}
              iconPosition="start"
              toggleIcon={(open) => {
                return (
                  <IconButton
                    iconClassName="fill-textColor-tertiary"
                    size="none"
                  >
                    {open ? <ClockIcon /> : <CloseCircleIcon />}
                  </IconButton>
                );
              }}
              inputClassName="w-[364px]"
              onClear={clearDateRange}
            />

            <button
              type="button"
              className="flex gap-2"
              onClick={() =>
                handleChange({
                  target: {
                    name: 'keepAssignment',
                    value: !values.keepAssignment,
                  },
                })
              }
            >
              <IconButton
                iconClassName="fill-brandingColor-primary-gradient"
                className="w-fit"
                size="none"
              >
                {values.keepAssignment ? <CheckedSquareIcon /> : <EmptySquareIcon />}
              </IconButton>

              <Typography variant="label">Keep assignment</Typography>
            </button>
          </div>

          <div className="flex w-full gap-x-2">
            <Button
              variant="outlined"
              color="basic"
              size="lg"
              className="w-full"
              onClick={() => toggleCopyTicketModal(isOpen)}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              className="w-full"
              disabled={isSaveDisabled}
            >
              Copy
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { CopyTicketModal };
export type { CopyTicketModalProps };
