import { FC } from 'react';
import { Tooltip } from '@mui/material';

import { Button } from 'src/shared/ui/button';

import { ZOOM_VALUES } from '../../helpers';

interface ZoomButtonsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  zoomValueIndex: number;
}

const ZoomButtons: FC<ZoomButtonsProps> = ({ onZoomIn, onZoomOut, zoomValueIndex }) => {
  return (
    <div className="flex gap-2 items-center justify-center fixed left-6 bottom-6 z-[999]">
      <Tooltip title="Zoom In">
        <Button
          variant="filled"
          color="primary"
          onClick={onZoomIn}
          disabled={zoomValueIndex === 0}
          className="!min-w-10 !rounded-full"
        >
          +
        </Button>
      </Tooltip>

      <Tooltip title="Zoom Out">
        <Button
          variant="filled"
          color="primary"
          onClick={onZoomOut}
          disabled={zoomValueIndex === ZOOM_VALUES.length - 1}
          className="!min-w-10 !rounded-full"
        >
          -
        </Button>
      </Tooltip>
    </div>
  );
};

export { ZoomButtons };
