import { FC, useState, ReactElement } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  TableManagerFieldValue,
  TypeNotation,
  RelationOptionsMap,
  MetadataMap,
  RelationOption,
  TagType,
  SurveyEntity,
  CustomComponentName,
  RequiredState,
} from 'src/shared/types';
import { DATE_FORMAT } from 'src/shared/utils';
import { MultiSelectAutocomplete } from 'src/shared/ui/multiSelectAutocomplete';
import { InputField } from 'src/shared/ui/inputField';
import { BasicSelectAutocomplete } from 'src/shared/ui/basicSelectAutocomplete';
import { DateTimeControl } from 'src/shared/ui/dateTimeControl';

import { AdminSurveyFormNavigator } from '../AdminSurveyFormNavigator';
import { DataGridContainerField } from '../DataGridContainerField';
import { FileUploadDialog } from '../FileUploadDialog';
import { DocumentTable } from '../DocumentTable';

interface FormFieldProps {
  schemaElement: TypeNotation;
  value: TableManagerFieldValue;
  isLoadingForm: boolean;
  requiredState?: RequiredState;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  formState: Record<string, TableManagerFieldValue>;
  idField: string;
  onChange: (field: string, value: TableManagerFieldValue) => void;
  isLoadingOrFetchingRelationOptions: boolean;
}

const FormField: FC<FormFieldProps> = ({
  schemaElement,
  value,
  isLoadingForm,
  requiredState,
  relationOptions,
  fieldsMetadata,
  formState,
  idField,
  onChange,
  isLoadingOrFetchingRelationOptions,
}) => {
  const { field, type, values = [], isOptional: isOptionalInSchema, isEditable } = schemaElement;
  const { modelName } = useParams<{ modelName: TagType }>();

  const [customRelationOptions, setCustomRelationOptions] = useState<RelationOption[]>([]);

  const isOptional =
    typeof requiredState === 'undefined'
      ? isOptionalInSchema
      : requiredState === RequiredState.Optional;

  const isCustomComponent =
    modelName && fieldsMetadata?.customComponents?.[modelName]?.[field]?.field === field;

  const label = fieldsMetadata?.headerNames?.[field]?.name ?? field;

  const modelNameData =
    modelName && fieldsMetadata?.customComponents?.[modelName]?.[field]?.settings?.modelName;

  const customComponents: { [key in CustomComponentName]?: ReactElement | null } = {
    AdminSurveyFormNavigator: isCustomComponent ? (
      <AdminSurveyFormNavigator
        items={value as Pick<SurveyEntity, 'id' | 'name'>[]}
        state={formState}
        label={label}
        withNavigation={false}
      />
    ) : null,
    DataGridContainerField:
      isCustomComponent && modelNameData ? (
        <DataGridContainerField
          modelName={modelNameData}
          state={formState}
          parentIdField={idField}
        />
      ) : null,
    FileUploadDialog:
      isCustomComponent && modelName ? (
        <FileUploadDialog
          relationOptions={relationOptions}
          modelName={modelName}
          schemaElement={schemaElement}
          fieldsMetadata={fieldsMetadata}
          state={formState}
          idField={idField}
        />
      ) : null,
    DocumentTable:
      isCustomComponent && modelName ? (
        <DocumentTable
          state={formState}
          modelName={modelName}
          idField={idField}
        />
      ) : null,
  };

  const handleCustomRelationOption = (value: RelationOption) => {
    setCustomRelationOptions((prev) => [value, ...prev]);
    onChange(field, value);
  };

  if (isCustomComponent) {
    const componentName = fieldsMetadata?.customComponents?.[modelName]?.[field]
      ?.componentName as CustomComponentName;

    const SelectedComponent = customComponents[componentName];

    if (SelectedComponent) {
      return <Box>{SelectedComponent}</Box>;
    }
  }

  if (
    field in relationOptions &&
    fieldsMetadata?.multiSelectFields?.includes(field) &&
    Array.isArray(value)
  ) {
    const handleSelectionChange = (newValue: RelationOption[]) => {
      onChange(field, newValue);
    };

    return (
      <MultiSelectAutocomplete
        items={relationOptions[field]}
        selectedItems={value as RelationOption[]}
        handleChange={handleSelectionChange}
        label={label}
        required={!isOptional}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        readOnly={!isEditable}
      />
    );
  }

  if (field in relationOptions) {
    const handleSelectionChange = (newValue: RelationOption | null) => {
      onChange(field, newValue);
    };

    return (
      <BasicSelectAutocomplete
        items={relationOptions[field]}
        selectedItem={value as RelationOption}
        handleChange={handleSelectionChange}
        required={!isOptional}
        label={label}
        onCustomValue={handleCustomRelationOption}
        customRelationOptions={customRelationOptions}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        readOnly={!isEditable}
      />
    );
  }

  if (type === 'enum') {
    const handleSelectionChange = (newValue: string | null) => {
      onChange(field, newValue);
    };

    return (
      <BasicSelectAutocomplete
        items={values}
        selectedItem={value as string}
        handleChange={handleSelectionChange}
        required={!isOptional}
        label={label}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        readOnly={!isEditable}
      />
    );
  }

  if (type === 'string') {
    return (
      <InputField
        type={type}
        name={field}
        label={label}
        value={(value as string) || ''}
        onChange={(e) => onChange(field, e.target.value)}
        required={!isOptional}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        InputProps={{
          readOnly: !isEditable,
          sx: {
            ...(!isEditable && {
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
                '&:hover fieldset': {
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
              },
            }),
          },
        }}
      />
    );
  }

  if (type === 'number') {
    return (
      <InputField
        type={type}
        name={field}
        label={label}
        value={value as string}
        onChange={(e) => onChange(field, e.target.value)}
        required={!isOptional}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        InputProps={{
          readOnly: !isEditable,
          sx: {
            ...(!isEditable && {
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
                '&:hover fieldset': {
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
              },
            }),
          },
        }}
      />
    );
  }

  if (type === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value as boolean}
            onChange={(e) => onChange(field, e.target.checked)}
            disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
            required={!isOptional}
            readOnly={!isEditable}
          />
        }
        label={label}
      />
    );
  }

  if (type === 'date') {
    const dateFormat =
      fieldsMetadata?.fieldsConfirurations?.[field]?.dateFormat || DATE_FORMAT.DEFAULT_ADMIN_PAGE;

    const includeTime = !!fieldsMetadata?.fieldsConfirurations?.[field]?.dateFormat;

    const handleDateChange = (newDate: string | null) => {
      onChange(field, newDate);
    };

    return (
      <DateTimeControl
        value={value as string}
        handleChange={handleDateChange}
        label={label}
        dateFormat={dateFormat}
        includeTime={includeTime}
        disabled={isLoadingForm || isLoadingOrFetchingRelationOptions}
        readOnly={!isEditable}
      />
    );
  }

  // TODO: Change on MUI component
  // eslint-disable-next-line etc/no-commented-out-code
  // if (
  //   type === 'array' &&
  //   isStringArray(value) &&
  //   !fieldsMetadata?.multiSelectFields?.includes(field)
  // ) {
  //   const stringsValue: string[] = [...value] as string[];

  //   return (
  //     <div className={clsx('flex flex-col gap-y-2 w-full')}>
  //       <TextField
  //         label={label}
  //         value={multiTextField}
  //         onChange={(e) => setMultiTextField(e.target.value)}
  //         onKeyDown={(e) => handleKeyDown(e)}
  //         startIcon={
  //           <DropDown
  //             options={{ placement: 'bottom-start' }}
  //             config={{
  //               closeOnSelect: false,
  //               focusLastElement: false,
  //               itemsElementClassName: (isOpen) =>
  //                 clsx(
  //                   'shadow-[0px_2px_66px_-10px_#0000000F]',
  //                   'rounded-lg',
  //                   'bg-[#F3F6FA]',
  //                   'w-full',
  //                   'p-4',
  //                   !isOpen && 'p-0 border-0',
  //                 ),
  //             }}
  //             renderElement={(isOpen) => (
  //               <div className="absolute left-1 w-[40px] flex justify-center items-center">
  //                 <Icons.Outlined.Chevrons.ArrowUpIcon
  //                   className={clsx(
  //                     'fill-[#2E3A59] transition-all cursor-pointer',
  //                     isOpen ? 'rotate-0' : 'rotate-180',
  //                   )}
  //                 />
  //               </div>
  //             )}
  //             items={stringsValue.map((item) => ({
  //               value: item,
  //               label: (
  //                 <DropDownItemMenu
  //                   endIcon={
  //                     <IconButton
  //                       size="md"
  //                       onClick={() => {
  //                         const newList = stringsValue.filter((el) => el !== item);
  //                         onChange(field, newList);
  //                       }}
  //                     >
  //                       <Icons.Outlined.Edit.TrashIcon />
  //                     </IconButton>
  //                   }
  //                   titleClassName="text-textColor-tertiary"
  //                 >
  //                   {item}
  //                 </DropDownItemMenu>
  //               ),
  //             }))}
  //           />
  //         }
  //         endIcon={
  //           <div className="absolute right-1">
  //             {multiTextField.length > 0 ? (
  //               <IconButton
  //                 color="basic"
  //                 size="md"
  //                 iconSize="md"
  //                 iconClassName="fill-[#231F20]"
  //                 onClick={() => handleAddItem(stringsValue)}
  //                 disabled={!multiTextField.length}
  //               >
  //                 <Icons.Outlined.Edit.PlusIcon />
  //               </IconButton>
  //             ) : (
  //               <Typography
  //                 variant="h3"
  //                 className="flex items-center justify-center size-[40px] text-semanticColor-success"
  //               >
  //                 {value.length}
  //               </Typography>
  //             )}
  //           </div>
  //         }
  //       />
  //     </div>
  //   );
  // }

  return null;
};

export { FormField };
