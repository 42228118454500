import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { useMediaQuery, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { showToastErrorMessage } from 'src/shared/utils';
import {
  TypeNotation,
  TagType,
  TableManagerFieldValue,
  RelationOptionsMap,
  MetadataMap,
  AdminPageContextType,
  ForeignKey,
} from 'src/shared/types';
import { selectCurrentUser, adminTableActions, selectCreateModalState } from 'src/store/slices';
import {
  useCreateTableRowMutation,
  useEditTableRowMutation,
  useLazyGetRelationValuesQuery,
} from 'src/store/api';
import { Spinner } from 'src/shared/ui/spinner';
import { useAppDispatch } from 'src/store';
import { ResponsiveDialog } from 'src/shared/ui/responsiveDialog';
import { HeaderDialog, FooterDialog, BodyDialog } from 'src/shared/ui/responsiveDialog/ui';

import { FormFields } from '../FormFields';
import {
  prepareDateValue,
  prepareMultiselectValue,
  prepareOptionValue,
  checkForChanges,
  getInitialState,
} from '../../helpers';

type CreateOrUpdateTableRowModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  schema: TypeNotation[];
  idField: string;
  modelName: TagType;
  relationOptions: RelationOptionsMap;
  setRelationOptionsFilters: Dispatch<SetStateAction<Record<string, Record<string, string>>>>;
  foreignKeys: ForeignKey[];
  fieldsMetadata: MetadataMap;
  selectedRow: Record<string, TableManagerFieldValue> | null;
  setSelectedRow: Dispatch<SetStateAction<Record<string, TableManagerFieldValue> | null>>;
  isLoadingOrFetchingRelationOptions: boolean;
};

const CREATE_ENTITY_FORM = 'createEntityForm';
const EMPTY_RELATION_OPTIONS_FILTERS = {};

const CreateOrUpdateTableRowModal: FC<CreateOrUpdateTableRowModalProps> = ({
  schema,
  idField,
  isOpen,
  modelName,
  relationOptions,
  setRelationOptionsFilters,
  foreignKeys,
  fieldsMetadata,
  selectedRow,
  setIsOpen,
  setSelectedRow,
  isLoadingOrFetchingRelationOptions,
}) => {
  const dispatch = useAppDispatch();
  const defaultCreateModalState = useSelector(selectCreateModalState);

  const { modelsOptions } = useOutletContext<AdminPageContextType>();
  const user = useSelector(selectCurrentUser);
  const [createTableRow] = useCreateTableRowMutation();
  const [editTableRow] = useEditTableRowMutation();
  const [
    trigger,
    {
      status: statusFetchingRelationValues,
      data: relationValues,
      isFetching: isFetchingRelationValues,
    },
  ] = useLazyGetRelationValuesQuery();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isEditMode = !!selectedRow;

  const [formState, setFormState] = useState(
    getInitialState({
      schema,
      fieldsMetadata,
      user,
      isEditMode,
      relationOptions,
      defaultCreateModalState,
      selectedRow,
    }),
  );

  const closeDialog = () => {
    setIsOpen(false);
    setSelectedRow(null);
    setFormState(
      getInitialState({
        schema,
        fieldsMetadata,
        user,
        isEditMode,
        relationOptions,
        defaultCreateModalState,
        selectedRow,
      }),
    );
    setRelationOptionsFilters(EMPTY_RELATION_OPTIONS_FILTERS);
    dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
    dispatch(adminTableActions.updateCreateModalValues(null));
  };

  useEffect(() => {
    if (
      statusFetchingRelationValues === 'fulfilled' &&
      relationValues &&
      typeof relationValues === 'object'
    ) {
      setFormState((prevState) => ({
        ...prevState,
        ...relationValues,
      }));
    }
  }, [relationValues]);

  const handleChange = (field: string, value: TableManagerFieldValue) => {
    const relatedOnChangeFields = fieldsMetadata?.relatedOnChangeFields;

    if (
      value === null &&
      relatedOnChangeFields &&
      field in relatedOnChangeFields &&
      relatedOnChangeFields[field]?.defaultValue
    ) {
      setFormState((prevState) => ({
        ...prevState,
        ...relatedOnChangeFields[field].defaultValue,
      }));
    }

    if (
      value &&
      typeof value === 'object' &&
      'value' in value &&
      relatedOnChangeFields &&
      field in relatedOnChangeFields
    ) {
      trigger({
        id: value.value as string,
        model: relatedOnChangeFields[field].model,
      });
    }

    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    const dependantForeignKeys = foreignKeys.filter((foreignKey) => {
      return foreignKey.filterFields?.some(({ field: filterField }) => filterField === field);
    });

    setRelationOptionsFilters((prevState) => {
      const updatedState = { ...prevState };

      dependantForeignKeys.forEach((foreignKey) => {
        const filterField = foreignKey.filterFields?.find(
          ({ field: filterField }) => filterField === field,
        );

        if (!filterField) {
          return;
        }

        let stringifiedValue = String(value);

        if (value && typeof value === 'object' && 'value' in value) {
          stringifiedValue = value.value;
        }

        if (value) {
          updatedState[foreignKey.field] = {
            ...updatedState[foreignKey.field],
            [filterField.param || filterField.field]: stringifiedValue,
          };
        } else {
          delete updatedState[foreignKey.field];
        }
      });

      return updatedState;
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const preparedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => {
          const preparedDate = prepareDateValue(value, key, fieldsMetadata, schema);
          if (preparedDate !== null) {
            return preparedDate;
          }

          const preparedMultiselect = prepareMultiselectValue(value, key);
          if (preparedMultiselect !== null) {
            return preparedMultiselect;
          }

          const preparedOption = prepareOptionValue(value, key, selectedRow);
          if (preparedOption !== null) {
            return preparedOption;
          }

          if (value === '' && (!selectedRow || selectedRow[key] === value)) {
            return [key, undefined];
          }
          if (value === null) {
            return [key, undefined];
          }

          const currentEL = schema.find((el) => el.field === key);
          const currentValue = currentEL?.type === 'number' ? Number(value) : value;

          return [key, currentValue];
        }),
      );

      setIsLoadingForm(true);

      const updatedFormState = checkForChanges(preparedFormState, selectedRow);

      if (isEditMode && selectedRow) {
        await editTableRow({
          id: String(selectedRow[idField]),
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          body: updatedFormState,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
        }).unwrap();
      } else {
        await createTableRow({
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          body: updatedFormState,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
        }).unwrap();
      }
    } catch (error) {
      showToastErrorMessage(`There was an error trying to create row table`);
    } finally {
      setIsLoadingForm(false);
      closeDialog();
      setRelationOptionsFilters(EMPTY_RELATION_OPTIONS_FILTERS);
    }
  };

  const buttonSubmit = !isEditMode ? 'Create' : 'Save';
  const headerNameModal = !isEditMode ? 'Create' : 'Edit';
  const isActionDisabled =
    isFetchingRelationValues || isLoadingForm || isLoadingOrFetchingRelationOptions;

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: fullScreen ? '100%' : '80%',
          padding: '1rem',
        },
      }}
    >
      <HeaderDialog
        title={`${headerNameModal} ${modelName}`}
        onClose={closeDialog}
      />
      <BodyDialog>
        <form
          id={CREATE_ENTITY_FORM}
          className="flex flex-col justify-between gap-y-6 min-h-[368px] min-w-[451px] w-full"
          onSubmit={handleSubmit}
        >
          <FormFields
            schema={schema}
            formState={formState}
            idField={idField}
            isLoadingForm={isActionDisabled}
            onChange={handleChange}
            relationOptions={relationOptions}
            fieldsMetadata={fieldsMetadata}
            selectedRow={selectedRow}
            isLoadingOrFetchingRelationOptions={isLoadingOrFetchingRelationOptions}
          />
        </form>
      </BodyDialog>
      <FooterDialog>
        <Button
          variant="contained"
          color="info"
          onClick={closeDialog}
          disabled={isActionDisabled}
          sx={{ minWidth: '6rem' }}
        >
          Cancel
        </Button>

        <Button
          form={CREATE_ENTITY_FORM}
          type="submit"
          variant="contained"
          color="success"
          autoFocus
          disabled={isActionDisabled}
          sx={{ minWidth: '6rem' }}
        >
          {isLoadingForm ? <Spinner size="xs" /> : buttonSubmit}
        </Button>
      </FooterDialog>
    </ResponsiveDialog>
  );
};

export { CreateOrUpdateTableRowModal };
