import dayjs from 'dayjs';

import {
  Activity,
  ActivityEntity,
  ActivityHealth,
  ActivityJob,
  ActivityState,
  ActivityTrack,
  ActivityUpdate,
  ActivityUpdated,
  WorkTrack,
} from 'src/shared/types';

import { HOURS_IN_DAY, MINUTES_IN_HOUR, XLSX_TYPE } from './constants';

export const getDayHours = () => Array.from({ length: 24 }, (_, i) => `${i + 1}:00`);
export const getHoursWithoutMinutes = () => Array.from({ length: 24 }, (_, i) => `${i + 1}`);

export const getHeaderDayHours = (isDay?: boolean) => {
  return isDay
    ? [
        '0 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
      ]
    : ['00', '3', '6', '9', '12', '15', '18', '21'];
};

export const getWorkTrackValue = (workTrack: ActivityHealth | ActivityTrack | ActivityUpdated) => {
  const title = Object.keys(WorkTrack).find((key) => key === workTrack);
  if (title) {
    return WorkTrack[title as keyof typeof WorkTrack];
  }

  return 'Updated';
};

export const getMinWidth = (element: HTMLElement) => {
  const clone = element.cloneNode(true) as HTMLElement;

  clone.style.position = 'absolute';
  clone.style.visibility = 'hidden';
  clone.style.whiteSpace = 'nowrap';
  clone.style.width = 'auto';
  clone.style.minWidth = 'initial';
  clone.style.maxWidth = 'initial';

  document.body.appendChild(clone);

  const contentWidth = clone.scrollWidth;

  document.body.removeChild(clone);

  return contentWidth;
};

// TODO: remove after API integration
export const getTestParentValues = () => [
  'ID120 Hydroblast For Inspection',
  'ID121 Hydroblast For Inspection',
  'ID122 Hydroblast For Inspection',
  'ID123 Hydroblast For Inspection',
  'ID124 Hydroblast For Inspection',
  'ID125 Hydroblast For Inspection',
  'ID126 Hydroblast For Inspection',
  'ID127 Hydroblast For Inspection',
];

const formatActivityStates = (states: ActivityState[], timeZone: string): ActivityUpdate[] => {
  return states.map((state) => ({
    id: state.id,
    reportedBy: state.reportedBy,
    jobNumber: state.jobNumber,
    workTrack: state.health,
    workProgress: state.progress,
    workStatus: state.track as ActivityTrack,
    startWork: dayjs.utc(state.startDate).tz(timeZone).toDate(),
    endWork: dayjs.utc(state.endDate).tz(timeZone).toDate(),
    actualStart: dayjs.utc(state.startDate).tz(timeZone).toDate(),
    actualEnd: dayjs.utc(state.endDate).tz(timeZone).toDate(),
    activityName: state.name,
    providerName: state.provider.ProviderName,
    providerId: state.provider.ProviderID,
    providerTeam: state.providerTeam,
    providerTeamId: state.providerTeamId || state.providerTeam.id,
    notes: state.notes,
    createdAt: state.createdAt,
    equipment: state.equipment,
    equipmentTypeId: state.equipmentTypeId,
    delayCategoryID: state.delayCategoryID,
    delayCategoryName: state.delayCategory?.DelayCategory,
    percentComplete: state.progress,
  }));
};

export const getFormattedActivities = (activities: Activity[], timeZone: string) => {
  return activities.map(({ id, activityStates, actualState }) => {
    const sortedStates = [...activityStates].sort((a, b) => {
      return dayjs(b.createdAt).toDate().getTime() - dayjs(a.createdAt).toDate().getTime();
    });

    const lastState = sortedStates[0];

    return {
      id,
      reportedBy: actualState.reportedBy,
      jobNumber: actualState.jobNumber,
      workTrack: actualState.health,
      workProgress: actualState.progress,
      workStatus: actualState.track,
      startWork: dayjs.utc(lastState.startDate).tz(timeZone).toDate(),
      endWork: dayjs.utc(lastState.endDate).tz(timeZone).toDate(),
      actualStart: dayjs.utc(actualState.startDate).tz(timeZone).toDate(),
      actualEnd: dayjs.utc(actualState.endDate).tz(timeZone).toDate(),
      activityName: actualState.name,
      providerName: actualState.provider.ProviderName,
      providerId: actualState.provider.ProviderID,
      providerTeam: actualState.providerTeam,
      providerTeamId: actualState.providerTeamId || actualState.providerTeam.id,
      notes: actualState.notes,
      delayCategoryID: actualState.delayCategoryID,
      delayCategoryName: actualState.delayCategory?.DelayCategory,
      updates: formatActivityStates(sortedStates, timeZone),
      equipmentTypeId: actualState.equipmentTypeId || '',
      delayDuration: actualState.delayDuration,
    };
  });
};

export const getISODurationFromHHmm = (timeOption: string) => {
  let hours = 0;
  let minutes = 0;
  if (timeOption.includes(':')) {
    [hours, minutes] = timeOption.split(':').map((elem) => +elem);
  } else {
    hours = +timeOption.slice(0, 2);
    minutes = +timeOption.slice(2, 4);
  }

  if (!hours && !minutes) {
    return 'PT0S';
  }

  return `PT${hours > 0 ? `${hours}H` : ''}${minutes > 0 ? `${minutes}M` : ''}`;
};

export const formatISODurationToHHmm = (duration: string): string => {
  const durationRegex = /P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?/;
  const match = duration.match(durationRegex);

  if (!match) {
    return '00:00';
  }

  const days = parseInt(match[1] || '0', 10);
  const hours = parseInt(match[2] || '0', 10);
  const minutes = parseInt(match[3] || '0', 10);

  const totalHours = days * HOURS_IN_DAY + hours;

  return `${String(totalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const getOverlappedCards = (cards: ActivityJob[]) => {
  const filteredCards = [cards[0]];
  const overlappedCards = [] as ActivityJob[];

  cards.forEach((card, index) => {
    if (index > 0) {
      const isInList = filteredCards.some(
        (prevCard) =>
          dayjs(card.actualStart).isBetween(
            dayjs(prevCard.actualStart),
            dayjs(prevCard.actualEnd),
          ) || dayjs(card.startWork).isSame(dayjs(prevCard.startWork), 'minute'),
      );

      if (!isInList) {
        filteredCards.push(card);
      } else {
        overlappedCards.push(card);
      }
    }
  });

  return {
    filteredCards,
    overlappedCards,
  };
};

export const isValidFile = (file: File) => {
  const isNotValidTypeFile = file.type !== XLSX_TYPE;

  if (isNotValidTypeFile) {
    return false;
  }

  return true;
};

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / MINUTES_IN_HOUR);
  const minutes = duration % MINUTES_IN_HOUR;

  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export const getReportChoicesEntities = (totalValues: { total: number; totalStates: number }) => [
  {
    label: ActivityEntity.Activities,
    amount: totalValues.total,
  },
  {
    label: ActivityEntity.States,
    amount: totalValues.totalStates,
  },
];
