import React, { useCallback, useEffect, useMemo } from 'react';
import { SafetyListItem } from 'src/shared/types';
import { useGetSafetyListQuery } from 'src/store/api';
import { SafetyPopupBody } from './ui/SafetyPopupBody';
import { LeafletMap } from 'src/features/admin/LeafletMap';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'src/store';
import { filterArrayByValue, getPaginationDays } from 'src/shared/utils';
import { configActions, FilterMode, selectConfig } from 'src/store/slices';
import { ChoosingPeriodButtons } from 'src/shared/ui/choosingPeriodButtons';
import { DateRangeSelector } from 'src/shared/ui/dateRangeSelector';
import { TodayButton } from '../activities/ui/todayButton';

const SAFETY_MAP_ZOOM = 5;

const SafetyMap: React.FC = () => {
  const dispatch = useAppDispatch();
  const { boardFilters, search } = useSelector(selectConfig);
  const dates = useSelector((state: RootState) => state.calendar.dates);
  const [startDate, endDate] = getPaginationDays(dates);

  const { data: list = [], isLoading } = useGetSafetyListQuery({
    filters: {
      ...boardFilters,
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    dispatch(configActions.setTypeBoardFilters(FilterMode.SafetyMap));
  }, []);

  const renderPopupBody = useCallback(
    (data: SafetyListItem) => <SafetyPopupBody item={data} />,
    [],
  );

  const filteredMarkers = useMemo(() => {
    if (!search) return list;
    return filterArrayByValue(list, search);
  }, [search, list]);

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="flex gap-3 md:gap-6 flex-row flex-wrap justify-center bg-white p-2 items-center">
        <ChoosingPeriodButtons withCustom />
        <DateRangeSelector isActivityPage />
        <TodayButton />
      </div>
      {!isLoading ? (
        <div className="relative w-full h-full flex flex-col gap-4 z-0">
          <LeafletMap
            markers={filteredMarkers}
            isSearchVisible
            renderPopupBody={renderPopupBody}
            zoom={SAFETY_MAP_ZOOM}
          />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { SafetyMap };
